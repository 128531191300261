.foreign {
    margin-left: 85px;
    margin-top: 96px;
    min-height: calc(100% - 100px)
}
.code {
    margin-left: 90px;
    width: 30%;

    @media (max-width: 768px){
        margin-left: 0px;
        margin-top: 65px;
        width: 49%;
    }
    
    @media (max-width: 508px){
        margin-left: 0px;
        margin-top: 65px;
        width: 49%;
    }
}
.plate {
    position: absolute;
    top: 88px;
    right: 0;
    width: 45%;
    @media (max-width: 768px){
        width: 42%;
    }
    
    @media (max-width: 768px){
        width: 47%;
        top: 147px;
    }
}
.label{
    @media (max-width:768px) {
        min-width: 406px;
    }
    @media (max-width:508px) {
        min-width: calc(100vw - 100px);
    }
}